#apclist {
    .table-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 20px auto;
        border: 1px solid #ddd;
    }
    .table-row {
        border-bottom: 1px solid #ddd;
        width:100%;
    }
    .table-row:last-child {
        border-bottom: none;
    }
    .table-cell {
        padding: 10px;
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        width:50%;
        float:left;
    }
    .table-cell:last-child {
        border-right: none;
    }
    .header {
        background-color: #f4f4f4;
        font-weight: bold;
    }
    a {
      word-wrap: break-word;
    }
}
