
.static-edit-menu {
    position: absolute;
    top: 160px;
    left:-164px;
    width: 200px;
    z-index: 10;
    transition: 0.6s;

    @media (max-width:@screen-md) { 
        top: 90px;
    }
    
    &.is-static-edit-menu--fixed {
        top: 10px;
        position: fixed;   
    }
    &:hover {
        left: -2px;
        
    }
    .static-edit-menu__expand-link {
        padding: 1px 5px;
        display: block;
        width: 36px;
        float:right;
        background-color: @default-background-colour;
        border-top: 2px solid @static-edit-menu-border-color;
        border-right: 2px solid @static-edit-menu-border-color;
        border-bottom: 2px solid @static-edit-menu-border-color;
        border-radius: 0 5px 5px 0;
        font-size: 14px;
    }
    .static-edit-menu__blurb-edit-off {
        color: red;
    }
    .static-edit-menu__blurb-edit-on {
        color: green;
    }
    
    .static-edit-menu__expand-icon {
        color: #f00;
    }
    .static-edit-menu__pullout-menu {
        list-style:none;
        padding: 0;
        width: 164px;
        float:left;
        background-color: @default-background-colour;
        border-top: 2px solid  @static-edit-menu-border-color;
        border-left: 2px solid  @static-edit-menu-border-color;
        border-bottom: 2px solid @static-edit-menu-border-color;
        border-radius: 0 0 0 0;
        line-height: 20px;
        font-size: 14px;
    }
    .static-edit-menu__pullout-menu-item {
        padding: 0px 10px;
        &.admin-link {
            display: inherit;
            margin-bottom: 0;
        }
    }
    
    .static-edit-menu__pullout-menu-item:not(:first-of-type) {
        border-right: 2px solid @static-edit-menu-border-color;
    }
    
    .static-edit-menu__pullout-menu-divider {
        border-bottom: 1px solid #bbb;
        padding-top: 30px;
    }
}
    
    .static-edit-menu__pullout-menu-item:last-child {
        .static-edit-menu__link {
            border-bottom: 0;
        }
    }
    
    .static-edit-menu__link {
        border-bottom: 1px solid #bbb;
        display:block;
        padding: 5px 1px;
        &:hover {
            text-decoration: none;
        }
   
    }
    

//default to hide blurb icons.
.is-sticky-enabled .blurb-editor {
    display: none;
}

.is-sticky-enabled.is-blurb-enabled .blurb-editor {
    display:inline;
}   



  
//material icons with different font sizes
.material-icons {
    &.material-icons--normal {
        font-size: 16px;
    }
    &.material-icons--large {
        font-size: 23px;
  }
   &.material-icons--larger {
        font-size: 25px;
  }
  &.material-icons--large-centre {
        position: relative;
        top: 3px;
  }
   &.material-icons--larger-centre {
        position: relative;
        top: 2px;
  }
 
}
  
 



