
/* Panel's Variable override */ 
@panel-body-padding:          15px 0;
@panel-heading-padding:       10px 0;
@panel-inner-border:          @dborder-color;
@panel-default-heading-bg:    @bg-transparent;
@panel-default-border:        @bg-transparent;


/* Panel override approach across the site of which we dont have variables availabe */
.panel{
	box-shadow: none;
	> .panel-heading{
		border-color: @panel-inner-border;
		.panel-title{
			font-size: @font-size-h3;
			font-weight: @panel-font-weight;
		}
	}
}

.facet-remove-options:empty {
    display:none;
}


/* Modify search section for flat design ( Border less ) styles */
.searchRefine {
	&.navbar{
		border: none;
		background-color: @bg-transparent;
		.modifyThisSearch{
			padding-left: 0;
		}
	}
	.modifyThisSearch .modifyThisSearch__button {
		margin-left: -15px;
	}
}

/*For borderless login box*/


@media (min-width: @screen-md-max) {
	h1{
		font-size: @font-size-h1;
	}

	.left-padding{
		padding-left: 15px;
	}
	.header-sidebar{
		.navbar-right {
			margin-right: 0;
		}
	}
}

