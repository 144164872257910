/* Global site search styles  */

@media (min-width:@max-full-width-search-box + 1) {
    .search-nav-container {
        position: relative;
    }
}

.search-nav {
    display: none;
    position: absolute;
    right: -6px;
    top: 45px;
    width: 430px;
    @media (max-width:@screen-sm-max) {
        right: -7px;
        top: 44px;
    }
    @media (max-width:@max-full-width-search-box) {
        position: absolute;
        right: 2vw;
        top: 46px;
        width: 96vw;
        
    }
        .search-nav__up-triangle {
       width: 0; 
        height: 0; 
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 20px solid @link-color;
        margin-left: 382px;
            @media (max-width:@screen-sm-max) {
               border-left: 10px solid transparent;
               border-right: 10px solid transparent;
               border-bottom: 10px solid @link-color;
               margin-left: 386px; 
            }
            @media (max-width:@max-full-width-search-box) {
                 margin-left: calc(~"100vw - 198px"); 
            }
            
             @media (max-width:@max-width-ultra-compact-ui) {
                 margin-left: calc(~"100vw - 176px"); 
            }
        }
        
        .search-nav__form {
            display: block;
            position: absolute;
            top:0;
            right:0;
            width:100%;
            &.search-nav__form--adv-search-enabled {
                background-color: @navbar-default-bg;
            }
        }
        .search-nav__advanced-search-link {
            font-size: ceil((@font-size-base * 0.9));
            padding: 5px 10px;
        }
        .search-nav__input-box {
            font-size: 22px;
            border: 4px solid @link-color;
            border-radius: 10px;
            padding: 13px 50px 13px 13px;
            position: relative;
            width:100%;
            line-height: 28px;
            z-index: 1;
            &:focus {
                box-shadow: none;
            }
            @media (max-width:@screen-sm-max) {
                font-size: 18px;
                border: 3px solid @link-color;
                border-radius: 7px;
                padding: 10px 50px 10px 10px;
                line-height:18px;
            }
        }
        
        .search-nav__submit-btn {
            position:absolute;
            top:23px;
            right: 0px;
            font-size:34px;
            background-color: transparent;
            border:none;
            z-index: 2;
            @media (max-width:@screen-sm-max) {
                top: 6px;
            }
            
                .material-icons {
                font-size:46px;
                padding-bottom:0;
                    @media (max-width:@screen-sm-max) {
                         font-size: 44px;
                    }
                }
                img {
                    height:46px;
                    //calculated using this tool https://angel-rs.github.io/css-color-filter-generator/
                    filter: var(--masthead-colour-filter, brightness(0) saturate(100%) invert(23%) sepia(58%) saturate(0%) hue-rotate(246deg) brightness(92%) contrast(76%));
                    @media (max-width:@screen-sm-max) {
                        height: 44px;
                   }
                }
            }
}




/* search results page */
.searchRefine .sortBy {
    float:right;
    label {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
    }
    .inactiveLink {
        color: @brand-primary;
    }
}

.resultItem__title {
    font-weight: @font-weight-search-result-listings;
}
.resultItem__title--link {
    color: @midnight-black;
    margin-bottom: 0;
    p {
        margin-bottom: 0;
        display: inline-block;
    }
}

.searchResultsContainerInner img.cover {
    max-width: 80px;
    margin:0 15px 15px 0;
    float:left;
}

.tools-nav-filter, .sort-by-list {
    a {
        text-decoration: none;
    }
    .dropdown-menu {
        padding: 0;
        border-radius: 0;
        min-width: 190px;
         > li {
            > a {
                padding: 10px;
                display: block;
                border-top: 1px solid @gray-lighter;
                font-weight: normal;
            }
            .inactiveLink {
                border-top: 1px solid @gray-lighter;
                padding: 10px;
                display: block;
                color: @gray;
                pointer-events: none;
                &:hover{
                    background-color: transparent;
                }
            }
        }
        .sidebar-pub2web-element {
            width: auto;
            padding: 0;
            box-shadow: none;
            border: none;
            float: none !important;
            margin: 0;
        }
    }
    &.open {
        .fa-caret-down {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }
    .panel-heading {
        display: none;
    }
    .panel-body {
        padding: 0;
        > ul {
            margin: 0;
            > li {
                > a {
                    text-decoration: none;
                    padding: 10px 10px;
                    display: block;
                    border-top: 1px solid @gray-lighter;
                    &:hover {
                        text-decoration: none;
                        background-color:  @dropdown-link-hover-bg;
                        color: @dropdown-link-hover-color;
                    }
                }
                .togglecontent {
                    margin: 5px 10px 5px 10px;
                }
            }
        }
    }
}

.tools-nav-filter {
    float: right;
    margin-right: 20px;
}

.search-listings-options__dropdown-link,
.search-listings-options__dropdown-link:link,
.search-listings-options__dropdown-link:visited,
.search-listings-options__dropdown-link:active {
    font-weight:bold;
}

#searchrefineform.navbar-left {
    margin-left: 0;
    float:left;
    .btn-primary {
        margin-left:10px;
    }
    padding-top:0;
    padding-bottom:0;
}

.searchRefine.navbar {
    margin-bottom: 10px;
    padding: 14px 0;
    p{
        margin-bottom: 0; 
    }
    .modifyThisSearch {
        float:left;
        margin-bottom: 0; 
    } 
}

#sortMobile + label {
    margin-right: 4px;
}

.searchRefineReturn {
    float: left;
    margin: 13px;
}

.gatherControls {
    margin: 10px 0;
    .addAllToCollection {
        padding:6px 12px;
        border: 1px solid @gray-lighter;
    }
}

.gatherItems {
    padding:6px 12px;
    border: 1px solid @gray-lighter;
}

#searchresultspage {
   .moreitemsloadingwrappper{
      float:right;
      text-align: center;
      padding-top: 10px;
      /*border:solid 1px black;*/
      @media only screen and (max-width:@screen-xs-max){
         width:100%;
      }
      @media only screen and (min-width:@screen-sm-min){
         width:81%;
      }
      @media only screen and (min-width:@screen-md-min){
         width:75%;
      }
   }
}

/*Included for Third Party worldCat */

.resultItemContainer {
    dt,dd {
        display:inline;
    }
    
    dl{
        margin-bottom: 0; //override bootstrap!
    }
    
    a[href^="http://worldcat.org"]:after {
          display: inline-block;
          font: normal normal normal @fa-font-size-base/@fa-line-height-base FontAwesome; // shortening font declaration
          font-size: 12px; // can't have font-size inherit on line above, so need to override
          text-rendering: auto; // optimizelegibility throws things off #1094
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        content: "\00a0@{fa-var-external-link}";
        }

    }   
 
 #thirdpartysearchresultspage .panel-title {
         line-height: 1.4em;
    font-weight: bold;
 }
 
 #relatedDatabasesSearchResults 
    .loading-more-results {
        background-color: @default-background-colour;
        border: 3px solid @brand-secondary;
        color: @brand-primary;
        cursor: wait;
        background-image: url('/images/admin/spinner.gif');
        background-repeat: no-repeat;
        background-position: 97% 50%;
        &:after {
            content: 'Wait loading.... '
     }   
   
}

/* pulled from AIP for facets! Oh DEAR not mobile first!  */
@media screen and (min-width:@screen-md-min) {
    .tools-nav {
        &.on-searchResults {
            display: none !important;
        }
    }

    .tools-nav-filter {
        .tools-nav {
            &.on-searchResults {
                display: block !important;
            }
        }
    }
}

.is-author-name-restrict {
    .author-list__item{
        display:none;
        &:nth-child(3):not(:last-child):after {
            content: "...";
        }
        &:nth-child(-n+3){
        display:inline;
        }
    }
}
.authors-item-display-container {
    position:relative;
    .elipsis_author {
        margin-right: 40px;
    }
}


@media (min-width: 767px){
    .text-min-sm-right{
        text-align: right;
    }
}

@media (max-width: 767px){
    .text-max-sm-right{
        text-align: right;
    }

    .dropdown-menu-sm-left{
        left: 0;
        right: auto;
    }
}

.search-main {
  a.search-main__advanced-search-link {
      color: @navbar-default-link-color;
   } 
}

@media (max-width:@screen-sm-max){
  .search-main {
      display:block;
      flex: 1 1 auto;
    .search-main__input-container,
    .search-main__advanced-search-container {
        display:none;
     } 
  }
}

@media (min-width:@screen-md) {
    
.header-container {
    display:flex;
    position:relative;
    .header-container__search {
        flex: 1 1 auto; //this overrides the default to display in the available space left
    }
}
    
    
.search-main {
        display:flex;
    justify-content: center;
    .search-main__input-container {
        width: 400px;
        position: relative;
    }
    .search-main__input {
        width: 100%;  
        padding:10px 60px 10px 10px;
        height:40px;
         
    }
    .search-main__advanced-search-container {
        padding: 10px 5px 10px 15px;
        font-size: ceil((@font-size-base * 0.9));
    }
    .search-main__submit-btn {
        position: absolute;
        top:0;
        right:0;
        height:40px;
        background-color: @inline-search_btn-colour;
        color: @inline-search_btn-text-colour;
        .material-icons {
           font-size:28px;
        }
        img {
            height: 28px;
            filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(2%) hue-rotate(86deg) brightness(105%) contrast(100%);
        }
    }
    .search-main__submit-btn.btn-link:hover,
    .search-main__submit-btn.btn-link:active,
    .search-main__submit-btn.btn-link:focus {
      color:@default-background-colour;
    }
}

}

@media (min-width: @screen-md-min) and (max-width:1068px) {
    .search-main{
        flex-direction:column;
        align-items: center;
        .search-main__advanced-search-container {
            padding: 5px 0 0 0;
        }
    }
}

.results-explanation {
    .results-explanation__heading-main {
        display: inline;
        .coverImageContainer .cover {
            max-width: 150px;
        }
        .results-explanation__heading-main__heading {
            vertical-align:top;
            margin-top:0;
        }
    }
    .results-explanation__main-search-term {
        font-size: 25px;
        line-height:27.5px;
    }
    .results-explanation__breakdown {
        display: inline;
    }
    .results-explanation__result-display-size {
        color: @brand-tertiary;
    }
    .results-explanation_description {
        margin-top: 10px;
    }
}

.facet-remove-options {
    margin:10px;
}

@media (max-width:@screen-xs-max) {
    .results-explanation h4,
    .results-explanation h2{ 
       margin-top:0;
       margin-bottom: 0;
    }
    
    .search__item {
        margin-bottom: 10px;
    }
    
    .searchResultsContainerInner.table-wrapper {
        padding: 0 0;
    }
    
    .table-wrapper.searchResultsContainerInner .table-cell.meta-data-container {
      padding-left:0;   
    }
    
    .facet-remove-options {
        margin:0;
        padding:0;
    }

}
