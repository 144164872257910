.paginator {
    .arrow {
        font-weight:bold;
    }
}

.paginator-container {
    p {
    display: inline;
    }
    display: inline-block;
}

.a_to_z_mobile {
    float: right;
}

.a_to_z {
    float: right;
    display: none;

    .pagination {
        margin:0;
         > li > a,
         > li > span {
             border: none;
             border-left: 1px solid @grey-light-custom;
         }

         li{
            a{
                &:hover{
                    color: @navbar-default-bg!important;
                }
            }
         }
    }
 
}

.facetContainer {
    padding:0;

    li.facetitem {
        padding-bottom: 3px;
    }
    .navbar-collapse {
         padding:0;
    }

    .browsefacetterm {
        padding: 5px 0;
    }
}
.publist-paginated {
    padding-left: 0;
    .browse-item {
        float:left;
        clear: both;
        width:100%; //fix for IE11 text overflow issue see SABINET-96.
        > li {
            display:table;
            > div {
                display: table-cell;
                vertical-align: top;
            }

        }

    }
   .coverImageContainer {
       padding-right: 10px;
       width: 94px;
   }
   .showhide .minus {
       display: none;
   }
}

.facet-remove-options {

    .facet-remove-options__item {
        font-size: 18px;
        max-width: 450px;
        display: flex;
        position: relative;
    }
    
    .facet-remove-options__item-text-only {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;
        line-height: 2em;
    } 
    .fa-stack {
        position: absolute;
        right: 0;
    }
    .facet-remove-options__item-text-only {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 85vw; /* fix for PLAT-11059 */
        display:block;
    } 
    .facet-remove-options__remove-icon {
        margin-top:0;
    }
    .facet-remove-options__remove-icon.fa-stack-2x {
        font-size: 25px;
        padding-top: 6px;
    }
    
}

.facet-filter-options {
    .facet-filter-options__btn {
        margin-top: 25px;
        display:block;
    }
    

    
    .facet-filter-options__pub-date-label {
        margin-top: 5px;
        margin-bottom: 5px;
        display:block;
    }
    .year-picker {
        margin-left:15px;   
    }
}

.facet-remove-options__item, 
.facet-remove-options__form {
    display:inline-block;
}
/* we will probably want to  make this a switchable style ? */
.facetContainer .facetitem__label {
    display: flex;
    .facetitem__name-label {
        font-weight: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1 1 auto;
    }
    .facetitem__count {
        padding:0 3px;
    }
}

.loader-overlay {
    position:fixed;
    width:100%;
    height:100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1060;
    cursor: wait;
   }
.month-picker, 
.month_to {
      max-width: 120px;
}
   
