/* Tabing styling */
 .transformer-tabs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 3px solid white;
}
.tabStlying {

    border-bottom-color: #9b59b6;
    background-color: @default-background-colour; 
    border-image: none;
    border-radius: @border-radius-base @border-radius-base 0 0;
    border-top: 1px solid @list-group-border;
    border-right: 1px solid @list-group-border;
    border-left: 1px solid @list-group-border;
    color: @nav-tabs-active-link-hover-color;
    cursor: default;
    display: block;
    line-height: 1.42857;
    margin-right: 2px;
    padding: 10px 15px;
}

 .transformer-tabs li.tab-one .active,
 .transformer-tabs li.tab-two .active,
 .transformer-tabs li.tab-three .active,
 .transformer-tabs li.tab-four .active,
 .transformer-tabs li.tab-five .active,
 .transformer-tabs li.tab-six .active,
 .transformer-tabs li.tab-seven .active,
 .transformer-tabs li.tab-eight .active{
	.tabStlying 
}

.transformer-tabs li:nth-child(1) .active,
.transformer-tabs li:nth-child(2) .active,
.transformer-tabs li:nth-child(3) .active,
.transformer-tabs li:nth-child(4) .active,
.transformer-tabs li:nth-child(5) .active,
.transformer-tabs li:nth-child(6) .active,
.transformer-tabs li:nth-child(7) .active,
.transformer-tabs li:nth-child(8) .active  {
	.tabStlying 
}
 .transformer-tabs a.active {
  border-bottom: 3px solid transparent; 
  position: relative;
  bottom: -3px;
}
.tab-pane {
	border:solid 1px @grey-light-custom;
	background-color: @default-background-colour; 
}
.transformer-tabs {
	position: relative;
	clear:both;
	 ul {
	    overflow: hidden;
	    position: relative;
	    border-bottom:none;
	    
		&.open a {
		  position: relative;
		  display: block;
		  border-radius: 0;
		} 
		    
	}
	
	 li {
	 	// Disabled state sets text to gray and nukes hover/tab effects
	    display: block;
	        	&.disabled > a {
     				color: @nav-disabled-link-color;

			    &:hover,
			    &:focus {
			        color: @nav-disabled-link-hover-color;
			        text-decoration: none;
			        background-color: transparent;
			        cursor: not-allowed;
		      	}
    		}
	}
	 a {
	  // commented out to resolve UN-307  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  padding: @nav-link-padding;
	  border-image: none;
	  border-radius: @border-radius-base @border-radius-base 0 0;
	  display: block;
	  line-height: 1.42857;
	  margin-right: 2px;
	  	 &.active {
	  	border: 0;
	  	z-index: 1;
		}
		&:hover,
      	&:focus {
        	text-decoration: none;
        	background-color: @nav-link-hover-bg;
      	}
	}


  }
.tabs > div {
  display: none;
  padding: 1rem;
}
#article .tabs > div {;
  min-height:185px;
}
.tabs > .active {
  display: block;
}


/* OK these are some sensible default values for (default bootstrap tabs) */
.nav.nav-tabs {
    margin-bottom: -2px;
}

.tab-content > .tab-pane {
    padding: @padding-large-vertical @padding-small-horizontal;
}

.tabs-center {
	text-align: center;
}

@media (min-width:@screen-lg-desktop){ 
        
        /* Generic Tabing */
    .transformer-tabs { 
        li {
            display: inline-block;
            float:left;
            padding: 0;
            vertical-align: bottom;
            margin-bottom: -1px;
            border-bottom: 1px solid @list-group-border;
        }
        a {
           display: inline-block;
           text-decoration: none;
           position: relative;
        }
    
         ul {
            &.open a {
                position: relative;
                display: block;
                background-color:@default-background-colour;
                }
            &::after {
             content: '';
                }
            margin-bottom: -1px;
            }
        li.tab-one .active, 
        li.tab-two .active, 
        li.tab-three .active {
            background-color:@default-background-colour;
            }
        
    }

}  

.tab-toggle {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: -1px;
    overflow: visible;
  }
  li {
    display: inline-block;
    padding: 0;
    vertical-align: bottom;
    border-bottom: 0;
  }
      a {
        display: inline-block;
        text-decoration: none;
        border-bottom: 2px solid #fff;
        border: 1px solid #ddd;  
        padding: 12px 10px 10px 10px; 
        
        &.active {
          position: relative;
          border-bottom: 3px solid #fff; 
          padding-top: 10px
        }
      }

  @media (max-width: @screen-md-max) {
    ul {
      border-bottom: 0;
      overflow: hidden;
      position: relative;
      &::after {
        content: "☰";
        position: absolute;
        top: 8px;
        right: 15px;
        z-index: 2;
        pointer-events: none;
      }
      &.open {
        a {
          position: relative;
          display: block;
        }
      }
    }
    li {
      display: block;
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &.active {
        z-index: 1;
        background-color: #fff;
        color: @text-color;
      }
    }
  }
}




 


