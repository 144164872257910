.summon-search-title {
   font-size: 13px;
   padding: 5px 10px;
}

.summon-search-title--homepage {
	color: #fff;
}
 
#contextual-search {
  margin-top:10px;
  margin-left: -3px;
  margin-bottom: 10px;
}

#contextual-search input[type='text'] {
   min-width: 174px;
}

@media (min-width: 768px) {
  .a_to_z {
     margin-top: 10px;
     width: 100%;
     float: none;
     margin-left: 10px;
  }
  #searchResultsContainer.browsesearch .topicPageRefineOptions {
     margin-top: -50px;
  }
  #contextual-search ~ .panel-body {
   padding: 5px 0;
  } 
}

@media (max-width: 767px) {
  #contextual-search {
    margin-top: 0;
  }
  #contextual-search ~ .panel-body {
   padding: 5px 0;
  }
  .btn-filter {
    padding: 5px;
  }
  .searchRefine.navbar {
    padding: 0;
  }
  .searchRefine .sortBy {
    padding: 10px
  }
  .searchRefine.navbar > div {
   width: 100%;
  }

}

.searchResultsContainerInner.table-wrapper {
  width:100%;
}

.normalsearch .searchRefine.navbar {
   float: none;
    width: 100%;
}

.facet-remove-options {
 z-index: 1;
}
